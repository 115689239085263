.yourcompany {
  display: flex;
  justify-content: center;
  color: aliceblue;
  background-color: rgb(33, 32, 88);
  width: 100%;
  height: 100%;
}

.yourcompany img {
  width: 55%;
  max-width: 400px;
  margin: 10px;
}

.kotak {
  padding-top: 15vh;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
}

.result {
  color: aliceblue;
}

.result table {
  width: 100%;
}

.table-left {
  width: 50%;
  text-align: right;
}

.table-right {
  width: 50%;
  text-align: left;
}

.result img {
  width: 40%;
  max-width: 250px;
}

#product {
  color: rgb(185, 223, 255);
}

.complain {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;

  font-size: 9pt;
  color: aliceblue;
}

.complain a{
  color: rgb(252, 103, 103);
  cursor: pointer;
}

.complain a:active{
  text-decoration: underline;
}

.power {
  position: fixed;
  width: 100%;
  color: rgb(160, 160, 160, 0.4);
  left: 0;
  bottom: 0;
  padding-bottom: 20px;
  text-align: center;
}