.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);

    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-inner {
    color: #fff;
    border-radius: 20px;
    position: relative;
    padding: 30px;
    padding-bottom: 20px;
    margin: 30px;
    width: 100%;
    max-width: 400px;
    background-color: #283F63;
}

.popup-inner .close-btn {
    position: absolute;
    color: #fff;
    background-color: #C75353;
    border: 0px;
    border-radius: 6px;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.popup-inner .close-btn:active {
    background-color: #c23232;
}

input {
    width: 100%;
    padding: 3px;
    border-radius: 6px;
    margin-bottom: 10px;
}

input:focus {
    border: 2px solid #996234;
    outline: 0px;
}

textarea {
    padding: 3px;
    border-radius: 6px;
    resize: vertical;
    height: 200px;
    width: 100%;
}

textarea:focus {
    border: 2px solid #996234;
    outline: 0px;
}

form button{
    margin-top: 20px;
    color: white;
    border: 0px;
    padding: 8px 30px;
    border-radius: 10px;
    cursor: pointer;
    background-color: #8f610c;
}

form button:active {
    background-color: #917440;
}